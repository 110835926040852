<template>
  <section
    class="bg-peacock-700"
    itemscope
    itemtype="https://schema.org/HowTo"
  >
    <div class="container flex flex-col gap-8 py-14 xl:flex xl:flex-row xl:gap-20">
      <div class="flex flex-none flex-col gap-6">
        <h2
          class="whitespace-pre-line text-2xl font-semibold text-white"
          itemprop="name"
        >
          <slot name="title" />
        </h2>
        <div class="flex flex-wrap gap-4">
          <NuxtLink
            :to="{ name: 'onboarding-step' }"
            class="btn btn-primary"
          >
            {{ $t('commons.actions.create_your_camper') }}
          </NuxtLink>
          <NuxtLink
            :to="{ name: 'rent-my-motorhome' }"
            class="btn btn-dark"
          >
            {{ $t('commons.actions.know_more') }}
          </NuxtLink>
        </div>
      </div>
      <ol class="flex flex-col gap-8 text-white sm:flex sm:flex-row">
        <li
          itemprop="step"
          itemscope
          itemtype="https://schema.org/HowToStep"
        >
          <span class="text-peacock-400 block text-3xl">01</span>
          <h3
            class="text-lg font-semibold"
            itemprop="name"
          >
            {{ $t('commons.actions.create_your_camper') }}
          </h3>
          <div
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/HowToDirection"
          >
            <p itemprop="text">
              {{ $t('pages.index.stay_in_control') }}
            </p>
          </div>
        </li>
        <li
          itemprop="step"
          itemscope
          itemtype="https://schema.org/HowToStep"
        >
          <span class="text-peacock-400 block text-3xl">02</span>
          <h3
            class="text-lg font-semibold"
            itemprop="name"
          >
            {{ $t('pages.index.receive_requests') }}
          </h3>
          <div
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/HowToDirection"
          >
            <p itemprop="text">
              {{ $t('pages.index.manage_all_your_requests') }}
            </p>
          </div>
        </li>
        <li
          itemprop="step"
          itemscope
          itemtype="https://schema.org/HowToStep"
        >
          <span class="text-peacock-400 block text-3xl">03</span>
          <h3
            class="text-lg font-semibold"
            itemprop="name"
          >
            {{ $t('pages.index.departure_imminent') }}
          </h3>
          <div
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/HowToDirection"
          >
            <p itemprop="text">
              {{ $t('pages.index.make_your_vehicle_profitable') }}
            </p>
          </div>
        </li>
      </ol>
    </div>
  </section>
</template>
